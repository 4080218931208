.location {
    max-width: 100rem;
    background-color: #180223;
    color: white;
}

.location h2 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
}

.title {font-size: 1.25rem;
    margin: 0.5rem 0;
    color: white;}
        .title1 {
            font-size: 1.25rem;
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;
            padding:0 0 0 0;
            color: white;
        }

.location ul {
    list-style: none;
    padding: 0;
    margin: 0;
}