.item {
    margin: 1rem 0;
    background-color: #0e0419;
    padding: 1rem;
}

.item h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    color:rgb(51, 235, 15)
}
.item h2 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    color: rgb(206, 8, 206)
}

.item header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantity span {
    margin: 1rem 0;
    justify-content: space-between;
    font-size: 1.0rem;
    font-weight: bold;
}

.description {
    font-size: 1.5rem;
    font-weight: bold;
}

.itemdescription {
    font-weight: normal;
    font-size: 1rem;
    font-style: Bold;
    color:ghostwhite
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
}

.actions button {
    background-color: transparent;
    border: 0px solid white;  
    margin-left: 0.5rem;
    padding: 0.15rem 1rem;
    color: white;
}

.actions button:hover,
.actions button:active {
    background-color: #cc9107;
    color: white;
}